@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap);
html {
  font-size: 14px;
}

body {
  background-color: rgb(236, 236, 236);
  margin: 0;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3e3e3e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
  padding: 0;
}

